import { useEffect } from 'react'

const GoogleTagManagerLoader = () => {
  useEffect(() => {
    const tagManagerArgs = {
      gtmId: 'GTM-N8P7FD6', // Google Tag Managerの ID
    }

    // Google Tag Managerスクリプトを動的に読み込む
    ;(function(w, d, s, l, i) {
      w[l] = w[l] || []
      w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != 'dataLayer' ? '&l=' + l : ''
      j.async = true
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl
      f.parentNode.insertBefore(j, f)
    })(window, document, 'script', 'dataLayer', tagManagerArgs.gtmId)
  }, [])

  return null
}

export default GoogleTagManagerLoader